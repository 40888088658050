import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
		<StyledContainer>
			<WebinarBody
				viewAllowed={props.viewAllowed}
			>
				<div className="flexBox">
					<div className="block block--left">
						<div className="block__content">
							<h2 data-aos="fade-up">Overview</h2>
							<p data-aos="fade-up">
							With both viruses constantly evolving, keeping current can be a daunting task. Lindsey Fish, MD,
							Medical Director at Peña Urgent Care Clinic, Denver Health and Hospital System led an expansive 
							discussion of the epidemiology of both viruses, as well as their timely diagnosis, trends in treatment, 
							and emerging guidelines.
							</p>
							<p data-aos="fade-up">
							She highlighted flu and COVID data relevant to urgent care including:
							</p>
							<ul data-aos="fade-up">
								<li>Epidemiology of 2022-2023 Influenza Season</li>
								<li>Epidemiology of 2022-2023 COVID-19</li>
								<li>Early Diagnosis Key to Effective Treatments (Test and Treat)</li>
								<li>Trends in Influenza Treatment</li>
								<li>Influenza Treatment Guidelines (who, what and when)</li>
								<li>Trends in COVID-19 Treatment</li>
								<li>COVID-19 Treatment Guidelines (who, what and when)</li>
								<li>Special Considerations (CXR utilization, superimposed PNA, ED/hospitalization)</li>
								<li>Key Takeaways</li>
							</ul>
						</div>
					</div>
					{props.featured === true ?
						null
						: (
							<div className="block block--right">
								<div className={(props.pageName === 'display' | props.viewAllowed === false) ? "accessBox show" : "accessBox show"}>
									<h3 data-aos="fade-up">Access Includes:</h3>
									<ul data-aos="fade-up">
										<li>Video recording replay</li>
									</ul>
								</div>
							</div>
						)
					}
				</div>

				<div className="block block--left">
					<div className="block__content presenter">
						<h2 data-aos="fade-up">About the Presenter</h2>
						<div className="presenterBio" data-aos="fade-up">
							<StaticImage
								src="../../../../assets/images/webinar-replay/fish.jpg"
								width={200}
								alt="Dr. Lindsey E. Fish"
								className="presenter__image"
							/>
							<p data-aos="fade-up">Dr. Lindsey E. Fish is an internist in Denver, Colorado and is affiliated with Denver 
							Health. She received her medical degree from University of California (San Francisco) School of Medicine 
							and has been in practice between 11-20 years.</p>
						</div>

					</div>
				</div>
			</WebinarBody>
		</StyledContainer>
	)
}

class FullBody extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			viewAllowed: false
		}
	}

	componentDidMount = props => {
		if (localStorage.getItem(this.props.localStorageID)) {
			this.setState({
				viewAllowed: true
			})
		}
	}

	render = () => (
		<BodyLanding
			pageName={this.props.pageName}
			viewAllowed={this.state.viewAllowed}
			localStorageID={this.props.localStorageID}
			featured={this.props.featured}
			featuredDate={this.props.featuredDate}
			featuredTime={this.props.featuredTime}
			featuredCost={this.props.featuredCost}
			featuredUrl={this.props.featuredUrl}
		/>
	)
}

export default FullBody
